export const NACH_STATUS_MAPPING = {
    INITIATED: "IN DRAFT",
    PENDING: "IN DRAFT",
    UNINITIATED: "NOT STARTED",
    FAILED: "FAILED",
    REGISTERED: "SUCCESS",
    AUTHENTICATED: {
        ENACH: "SUCCESS",
        FAC_ENACH: "SUCCESS",
        PHYSICAL_NACH: "IN DRAFT",
        FAC_PHYSICAL_NACH: "IN DRAFT"
    }
};

export const NACH_VENDOR_MAPPING = {
    DIGIO: "Digio",
    LOTUS_PAY: "Lotus Pay",
    ESCROW_PAY: "Escrow Pay",
    DUMMY: "Dummy"
};

export const NACH_TYPE_MAPPING = {
    ENACH: "E-NACH",
    PHYSICAL_NACH: "Paper",
    FAC_ENACH: "FAC-ENACH",
    FAC_PHYSICAL_NACH: "FAC-PAPER"
};

export const MODAL_TEXTS = {
    TITLE: "Alert!",
    DELETE_WITH_REPAYMENTS: "Ongoing repayments found for this Auto-debit (NACH). Are you sure you want to delete?",
    DELETE_CONFIRMATION: "Are you sure you want to delete?",
    PRIMARY_BUTTON: "Delete",
    SECONDARY_BUTTON: "Cancel"
};

export const NOTIFICATION_TEXTS = {
    DELETE_SUCCESS: "NACH deleted successfully",
    DELETE_ERROR: "Failed to delete NACH",
    CHECK_REPAYMENTS_ERROR: "Failed to check active repayments"
};

export const TOOLTIP_TEXTS = {
    NO_DELETE_ACCESS: "You do not have required access"
};

export const AUTHORIZED_EMAILS = {
    NACH_DELETE: "abhishek.tiwari@recur.club"
};