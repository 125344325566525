import _, { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { downloadFileFromArrayBuffer, showNotification } from '../../../../../../../util/utility';
import { getObjAndFileName } from './Overview/overviewUtility';
import { useSelector } from 'react-redux';
import callApi from '../../../../../../../util/apiCaller';
import { getInvesteesOrganization } from '../../../../../AdminReducers';
import { DOC_SERVICE_RESPONSE, GENERIC_ERROR, SHOW_NOTIFICATION_STATUS } from '../../../../../../../constants/enums';
import moment from 'moment';
import { checkNachActiveRepayments, deleteNach, editNach, fetchBankAccountListHelper, toggleBankAccountStatusHelper } from '../../../../../../../common/ApiHelpers';
import { DECENTRO_BANK_VERIFIED_SUCCESS, NOT_VERIFIED, VERIFIED } from './DocumentVault/consts/consts';
import styles from './Bank.module.scss';
import { toggleBankAccountStatus } from '../../../../../../../common/ApiContainer';
import NachAccountChangeTooltip from '../../../../NachCollectionsPage/components/NachAccountChangeTooltip';
import ReactTooltip from 'react-tooltip';
import { getCheckIfUserHasRelevantPermissions, getEmail, getUserId } from '../../../../../../App/AppReducer';
import RedDeleteIcon from '../../../../../../../assets/Icons/RedDeleteIcon';
import ConfirmationModal from '../../../../../../../components/ConfirmationModal/ConfirmationModal';
import { NACH_STATUS_MAPPING, NACH_VENDOR_MAPPING, NACH_TYPE_MAPPING, MODAL_TEXTS, NOTIFICATION_TEXTS, TOOLTIP_TEXTS, AUTHORIZED_EMAILS } from './NachConstants';


export default function Banks({
    toggleAddBank,
    toggleCreateNach,
    nachList,
    setBank,
    handleNachSyncStatus,
    setNachListState,
    fetchAllNach
}) {

    const {Status, Verified, VerifiedText, NotVerified, NotVerifiedText, Nach, NachText, GrayButton, BlueButton, toggle} = styles;

    const [isDownloading, setIsDownloading] = useState(null);
    const [viewEdit, setViewEdit] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [editReload, setEditReload] = useState(false);
    const [bankListData,setBankListData] = useState([]);
    const investorOrganization = useSelector(getInvesteesOrganization)
    const investeeOrganization = useSelector(getInvesteesOrganization)

    const userId = useSelector(getUserId);
    const userEmail = useSelector(getEmail);
    const isUserHasUWEditAccess = useSelector((state) =>
      getCheckIfUserHasRelevantPermissions(state, ["underwriting:edit"])
    );

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedNach, setSelectedNach] = useState(null);
    const [hasActiveRepayments, setHasActiveRepayments] = useState(false);


    const canDeleteNach = useSelector((state) => {
        return (getCheckIfUserHasRelevantPermissions(state, ["revops", "underwriting:edit"]) || 
            userEmail === AUTHORIZED_EMAILS.NACH_DELETE);
    });

    useEffect(()=>{
        fetchBankListData();
    },[])

    const fetchDoc = async (docId, index) => {
        if (!docId) return;
        setIsDownloading(index);
        const res = await callApi(`file/downloadFilePresigned?fileId=${docId}`, "post", {}, {}, false, false, false, false, false, true);
        if (res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS) {
            window.open(res.signedUrl, "_blank");
        }
        else {
            const message = res.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification("Error", message);
        }
        setIsDownloading(null);
    }

    const toggleBankStatus = async (currentStatus, is_nach_enabled,bankId, orgId) => {
        if(!!is_nach_enabled){
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, "Not allowed for nach account")
            return;
        }
        const toggledValue = !currentStatus;
        const data = {
            organizationBankId: bankId,
            value: toggledValue,
            investeeOrgId: orgId,
        }
        await toggleBankAccountStatusHelper(data)
        await fetchBankListData();
    }
    const fetchBankListData = async () => {
        if (!investeeOrganization?._id) {
            return;
        }
        let data = {
            orgType: "investee",
            orgId: investeeOrganization?._id
        }
        const resData = await fetchBankAccountListHelper(data);
        const bankData = resData?.data?.banklist ?? [];
        setBankListData(bankData);
    }

    const handleDownloadPaperNach = (letter, index) => {
        if (!isNaN(Number(letter))) {
            fetchDoc(letter, index);
            return;
        }
        setIsDownloading(index);
        const { obj, fileName } = getObjAndFileName(letter)
        const getBuffer = (data) => {
            return data.file.Body.data;
        }
        const cb = () => setIsDownloading(null);
        downloadFileFromArrayBuffer("deals/admin/download-from-s3", "post", obj, null, fileName, { type: "application/pdf" }, getBuffer, cb)
    }



    const handlePaymentDivision = () => {
        callApi('nach_details/nach_scheduling/payment_division', 'post', { investee_organization_id: investorOrganization?._id, name: investorOrganization?.name })
            .then(res => {
                if (res.status === "Success") {
                    showNotification("success", "Payment Divided Successfully!")
                }
            })
    }

    const getNachStatus = (status, type) => {
        if (status === "AUTHENTICATED") {
            return NACH_STATUS_MAPPING.AUTHENTICATED[type] || "Generated";
        }
        return NACH_STATUS_MAPPING[status] || "Generated";
    }

    const getNachVendorMapping = (type) => {
        return NACH_VENDOR_MAPPING[type] || "-";
    }

    const getNachType = (type) => {
        return NACH_TYPE_MAPPING[type] || "Paper";
    }

    function isValidDateFormat(dateString) {
        const parsedDate = moment(dateString, "DD-MM-YYYY", true);      
        return parsedDate.isValid() && parsedDate.format("DD-MM-YYYY") === dateString;
      }

    const TruncatedBankInfo = ({ bankName, accountNumber }) => {
        let truncatedBankName = bankName;
        if(bankName.length > 5) {
            truncatedBankName = `${bankName.substring(0, 5)}...`;
        }
        const truncatedAccountNumber = accountNumber.substring(accountNumber.length - 4);
      
        return (
          <span title={`${bankName} - ${accountNumber}`}>
            {truncatedBankName}-{truncatedAccountNumber}
          </span>
        );
      };

    const renderEditButton = (item, index) => {
        if(isDownloading === index) return;
        if(editReload === true && viewEdit === index) {
            return (
                <div id="loading-spinner">
                    <div className="spin-icon"></div>
                </div> 
            );
        };

        if(viewEdit === index) {
            return (
                <div>
                    <span onClick={() => handleEditYesClick(item)}>
                        <img style={{ width: "14px" }} src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/select-yes.svg" alt="Edit" />
                    </span>
                    <span className='ml-3' onClick={handleCloseEdit}>
                        <img style={{ width: "10px" }} src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/Shape.svg" alt="Edit" />
                    </span>
                </div>
            )
        }
    }

    const handleEditIconClick = (item, index) => {
        setViewEdit(index);
        setEndDateValue(item.mandateEndDate)
    }

    const handleCloseEdit = () => {
        setViewEdit(null);
        setEndDateValue(null);
    }

    const handleNachEditChange = (value,index) => {
        const formattedDate = moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
        setEndDateValue(formattedDate);
    }

    const handleEditYesClick = async (item) => {
        setEditReload(true);
        if(endDateValue === null) {
            return showNotification("error","Please select end date value");
        }
        let data = {
            nachMandateId: item.nachMandateId,
            payerId: item.investeeOrgId,
            updatedEndDate: endDateValue
        }
        const res = await editNach(data);

        if(res) {
            fetchAllNach()
            setViewEdit(null);
            setEndDateValue(null);
            showNotification("success", "Successfully edited Nach End Date")
        }
        setEditReload(false);
    }
    
    const renderActionButton = (item, index) => {
        if (item.nachMandateDocId) {
            if (index === isDownloading) {
                return (
                    <div id="loading-spinner">
                        <div className="spin-icon"></div>
                    </div>
                );
            }
            return (
                    <span onClick={() => handleDownloadPaperNach(item.nachMandateDocId, index)}>
                        <img style={{ width: "12px" }} src="/assets/download-blue.svg" alt="Download" />
                    </span>
            );
        } else if (item.authenticationLink) {
            return (
                <img
                    onClick={() => {
                        navigator.clipboard.writeText(item.authenticationLink);
                        showNotification("Success", "Nach link copied");
                    }}
                    style={{ width: "12px" }}
                    src="/assets/copy-icon-blue.svg"
                    alt="Copy"
                />
            );
        }
        return null;
    };

    const renderDeleteButton = (item) => {
        const status = getNachStatus(item.nachMandateStatus, item.nachMandateType);
        
        if (status === NACH_STATUS_MAPPING.UNINITIATED) {
            return null;
        }

        return (
            <span 
                className="ml-3" 
                data-tip=""
                data-for={`delete-nach-${item.nachMandateId}`}
            >
                <RedDeleteIcon 
                    onClick={() => canDeleteNach ? handleDeleteNach(item) : null}
                    style={{ 
                        cursor: canDeleteNach ? 'pointer' : 'not-allowed',
                        opacity: canDeleteNach ? 1 : 0.5
                    }}
                />
                {!canDeleteNach && (
                    <ReactTooltip
                        id={`delete-nach-${item.nachMandateId}`}
                        type="light"
                        place="bottom"
                        className="payout-tooltip"
                        border={true}
                        borderColor="black"
                        delayShow={50}
                        multiline={true}
                    >
                        <div className="tooltip-content">
                            {TOOLTIP_TEXTS.NO_DELETE_ACCESS}
                        </div>
                    </ReactTooltip>
                )}
            </span>
        );
    };

    const handleDeleteNach = async (item) => {
        setSelectedNach(item);
        const status = getNachStatus(item.nachMandateStatus, item.nachMandateType);
        const statusesToCheck = [
            NACH_STATUS_MAPPING.INITIATED,
            NACH_STATUS_MAPPING.REGISTERED,
            NACH_STATUS_MAPPING.AUTHENTICATED[item.nachMandateType] || NACH_STATUS_MAPPING.AUTHENTICATED.ENACH
        ];
        
        if (statusesToCheck.includes(status)) {
            try {
                const response = await checkNachActiveRepayments({
                    referenceId: item.referenceId
                });
                if (response?.hasActiveRepayments !== undefined) {
                    setHasActiveRepayments(response.hasActiveRepayments);
                    setShowDeleteModal(true);
                } else {
                    showNotification("error", NOTIFICATION_TEXTS.CHECK_REPAYMENTS_ERROR);
                    setSelectedNach(null);
                }
            } catch (error) {
                showNotification("error", NOTIFICATION_TEXTS.CHECK_REPAYMENTS_ERROR);
                setSelectedNach(null);
                setShowDeleteModal(false);
                return;
            }
        } else {
            setHasActiveRepayments(false);
            setShowDeleteModal(true);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            console.log("selectedNach.nachMandateId", selectedNach.nachMandateId);
            const response = await deleteNach({
                nachVendor: selectedNach.nachVendor,
                nachMandateId: selectedNach.nachMandateId,
                hasActiveRepayments,
                userId: userId,
                orgId: investeeOrganization?._id,
                investorName: selectedNach.investorName,
                amount: selectedNach.nachMandateMaxAmount,
                companyName: investeeOrganization?.name
            });

            if (response.isDeleted) {
                fetchAllNach();
                showNotification("success", NOTIFICATION_TEXTS.DELETE_SUCCESS);
            } else {
                showNotification("error", response?.message ?? NOTIFICATION_TEXTS.DELETE_ERROR);
            }
        } catch (error) {
            showNotification("error", NOTIFICATION_TEXTS.DELETE_ERROR);
        } finally {
            setShowDeleteModal(false);
            setSelectedNach(null);
        }
    };

    return (
        <>
        {showDeleteModal && <ConfirmationModal
            title={MODAL_TEXTS.TITLE}
            description={hasActiveRepayments 
                ? MODAL_TEXTS.DELETE_WITH_REPAYMENTS
                : MODAL_TEXTS.DELETE_CONFIRMATION}
            primaryButtonText={MODAL_TEXTS.PRIMARY_BUTTON}
            secondaryButtonText={MODAL_TEXTS.SECONDARY_BUTTON}
            onPrimaryButtonClick={handleConfirmDelete}
            onSecondaryButtonClick={() => setShowDeleteModal(false)}
            onClose={() => setShowDeleteModal(false)}
            isAlert={true}
        />}
        <div className="admin-data-table1" style={{ border: "none", marginLeft: "30px", marginRight: "30px", marginTop: "35px", marginBottom: "15px", display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontWeight: "600", fontSize: "22px" }}>Bank Account</span>
                {/* <span style={}> */}
                    {/* <button
                        onClick={() => toggleAddBank()}
                        className="add-new-bank-btn"
                        style={{}}
                    >
                    NACH ACCOUNT
                    </button> */}
                    <div>
                        {isUserHasUWEditAccess && <span
                            data-tip data-for="nach-account-change"
                        >
                            <button
                            className="change-nach-btn">
                                NACH Account
                            </button>
                        <NachAccountChangeTooltip bankListData={bankListData} fetchBankListData={fetchBankListData}/>
                        </span>}
                        <button
                            onClick={() => toggleAddBank()}
                            className="add-new-bank-btn"
                        >
                            <img
                                src="/assets/bank.svg"
                                alt="" style={{ width: '12px' }}
                                className='mr-1'
                            />
                            Add New Bank
                        </button>
                    </div>
                {/* </span> */}
            </div>
            <div className="admin-data-table1 admin-bank-account" style={{ marginLeft: "30px", marginRight: "30px" }}>
                <table className="table team" >
                    <thead className="team-head">
                        <tr >
                            <th scope="col" className="pl-2 fw600">
                                Account Holder Name
                            </th>
                            <th scope="col" className="pl-2 fw600">
                                Nick Name
                            </th>
                            <th scope="col" className='fw600'>Account No.</th>
                            <th scope="col" className='fw600'>Bank Name</th>
                            <th scope="col" className='fw600'>IFSC Code</th>
                            <th scope="col" className='fw600'>Status</th>
                            <th scope="col" className='fw600'>ESP Bank ID</th>
                        </tr>
                    </thead>
                    <tbody className="team-body">
                        {_.isEmpty(bankListData) ? null : (
                            <>
                                {bankListData.map((item, index) => (
                                    <tr key={index}>
                                        <td className="pl-2">{item.account_holder_name}</td>
                                        <td className="pl-2">{item.nick_name}</td>
                                        <td className="blue1">{item.account_number}</td>
                                        <td className="blue1">{item.bank_name}</td>
                                        <td className="blue1">{item.ifsc_code}</td>
                                        <td className="blue1">
                                            <div className='d-flex align-items-center'>
                                                {item.is_nach_enabled ? 
                                                (<span className={`${Status} ${Nach}`}>
                                                    <span className={NachText}>NACH ACCOUNT</span>
                                                </span>) :
                                                    item.pennyDropVerificationStatus===DECENTRO_BANK_VERIFIED_SUCCESS ? (
                                                        <span className={`${Status} ${Verified}`}>
                                                            <span className={VerifiedText}>{VERIFIED.toUpperCase()}</span>
                                                        </span>
                                                    ) : (
                                                        <span className={`${Status} ${NotVerified}`}>
                                                            <span className={NotVerifiedText}>{NOT_VERIFIED.toUpperCase()}</span>
                                                        </span>
                                                        )
                                                }
                                            </div>
                                        </td>
                                        {!isNil(item.escrow_pay_bank_id) && 
                                        <td className="blue1">{item.escrow_pay_bank_id}</td>
                                        }
                                         {isNil(item.escrow_pay_bank_id) && 
                                        <td className="blue1">
                                        <button onClick={() => {
                                            if(!item.pennyDropVerificationStatus || item.pennyDropVerificationStatus!==DECENTRO_BANK_VERIFIED_SUCCESS) {
                                                toggleAddBank();
                                                setBank(item);
                                            }
                                        }}   className = {!item.pennyDropVerificationStatus || item.pennyDropVerificationStatus!==DECENTRO_BANK_VERIFIED_SUCCESS ? BlueButton: GrayButton}>
                                          <img
                                            src="/assets/create.png" // Change the image path as needed
                                            alt=""
                                            style={{ height: "12.5px", width: "auto" }}
                                          />
    <span style={{ marginLeft: "5px" }}>Edit Bank</span>
                                        </button>
                                      </td>
                                        
                                        }
                                        {
                                            <td>
                                                <div className={toggle} >
                                                    <label
                                                        className="checkbox-container"
                                                        style={{ width: "100px"}}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={(item?.isActive) ? true : false}
                                                            onChange={() => toggleBankStatus(item?.isActive,item?.is_nach_enabled, item?._id, item?.organization_id)}
                                                        />
                                                        <span style={{ marginLeft: "5px", fontSize: "12px" }}>
                                                            {(item?.isActive) ? "Active" : "Inactive"}
                                                        </span>
                                                    </label>
                                                </div>

                                            </td>
                                        }
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="admin-data-table1" style={{ border: "none", marginTop: "35px", marginLeft: "30px", marginRight: "30px", marginBottom: "15px", display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontWeight: "600", fontSize: "22px", fontFamily: "Gilroy-SemiBold" }}>NACH</span>
                <div>
                    <button
                        onClick={() => toggleCreateNach()}
                        className="create-new-nach-btn"
                    >
                        <img
                            src="/assets/nach.svg"
                            alt="" style={{ width: '12px' }}
                            className='mr-1'
                        />
                        Create NACH
                    </button>
                </div>
            </div>
            {nachList ? <div className="admin-data-table1 admin-nach" style={{ marginLeft: "30px", marginRight: "30px" }}>
                <table className="table team" >
                    <thead className="team-head">
                        <tr >
                            <th scope="col" className="fw600 nach-entry-style">
                                Bank Account No
                            </th>
                            <th scope="col" className="fw600 nach-entry-style">
                                Lender Name
                            </th>
                            <th scope="col" className="fw600 nach-entry-style">
                                NACH Vendor
                            </th>
                            <th scope="col" className="fw600 nach-entry-style">
                                NACH Type
                            </th>
                            <th scope="col" className="fw600 nach-entry-style">
                                Amount
                            </th>
                            <th scope="col" className="fw600 nach-entry-style">
                                Status
                            </th>
                            <th scope="col" className="fw600 nach-entry-style">
                                Created On
                            </th>
                            <th scope="col" className="fw600 nach-entry-style">
                                End Date
                            </th>
                            <th></th>
                            <th></th>
                            {/* <th scope="col" className='fw600'>Investor Name(Deal)</th> */}
                        </tr>
                    </thead>
                    <tbody className="team-body">
                        {_.isEmpty(nachList) ? null : (
                            <>
                                {nachList.map((item, index) => (
                                    <tr key={index}>
                                        <td className='nach-entry-style'>
                                            <TruncatedBankInfo bankName={item.nachBankName} accountNumber={item.nachBankAccountNumber}/>
                                        </td>
                                        <td className='nach-entry-style' style={{color: "#4C4C4C"}}>{item.investorName}</td>
                                        <td className='nach-entry-style'>{getNachVendorMapping(item.nachVendor)}</td>
                                        {/* <td className="blue1" style={{maxWidth: '230px'}}>{item.investorName ?? "-"}</td> */}
                                        <td className='nach-entry-style'><span className={`nach-type-${getNachType(item.nachMandateType)}`}>{getNachType(item.nachMandateType)}</span></td>
                                        {/* <td className="blue1">{item.umrn ?? "-"}</td> */}
                                        <td className='nach-entry-style'>{Number(item.nachMandateMaxAmount)}</td>
                                        <td className='nach-entry-style'><span className={`nach-status-${getNachStatus(item.nachMandateStatus, item.nachMandateType)}`}>{getNachStatus(item.nachMandateStatus, item.nachMandateType)}</span></td>
                                        <td className='nach-entry-style'>{item.mandateCreatedAt}</td>
                                        <td className='nach-entry-style'>
                                        {item.nachMandateStatus === "UNINITIATED" || !isValidDateFormat(item.mandateEndDate) ? 
                                        <span className='ml-2'>
                                            -
                                        </span>
                                         :
                                         <div>
                                            
                                            <input
                                                title={"index"}
                                                name={"tot_expiry_date"}
                                                value={
                                                    endDateValue !== null && viewEdit === index
                                                    ? moment(endDateValue, "DD-MM-YYYY").format("YYYY-MM-DD")
                                                    : moment(item.mandateEndDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                                                }
                                                type="date"
                                                style={{
                                                    fontSize: '11px',
                                                    border: viewEdit === index ? '1px solid #4C8DFF' : 'none', 
                                                    padding: viewEdit === index ? '1px' : 'none', 
                                                }}
                                                onChange={(e)=>handleNachEditChange(e.target.value,index)}
                                                readOnly={viewEdit!==index}
                                                min={moment(item.mandateCreatedAt, "DD-MM-YYYY").format("YYYY-MM-DD")}
                                            />
                                            {viewEdit != index && item.nachMandateStatus === "INITIATED" &&
                                                <span onClick={() => handleEditIconClick(item, index)}>
                                                    <img style={{ width: "12px" }} src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/create.svg" alt="Edit" />
                                                </span>
                                            }
                                        </div>}

                                        </td>
                                        <td className='nach-entry-style' style={{cursor: "default"}}>
                                            {renderDeleteButton(item)}
                                        </td>
                                        <td className='nach-entry-style'>
                                            {viewEdit !== index ?
                                                <>
                                                    {item.nachMandateStatus === "INITIATED" &&
                                                        <span>
                                                            {renderEditButton(item, index)}
                                                        </span>
                                                    }
                                                    <span className='ml-3'>
                                                        {renderActionButton(item, index)}
                                                    </span>
                                                </> : 
                                                renderEditButton(item, index)
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
            </div> :
                <div className="admin-data-table1 admin-nach" style={{ marginLeft: "30px", marginRight: "30px" }}>
                    <table className="table team" style={{ display: "block", textAlign: "center", marginTop: "100px" }}>
                        No NACH created yet!
                    </table>
                </div>
            }
        </>
    )
}
