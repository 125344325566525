export const updatedByDealEvaluation = {
  ADMIN : "Admin",
  SYSTEM : "System"
}
export const ipaStatus={
  ACCEPTED:"ACCEPTED",
  SHARED:'SHARED'
}

export const DEAL_EVALUATION_INITIAL_STATE = {
    uwSignal: null,
    promoterCibilScore: null,
    companyCibilScore: null,
    receivables: null,
    relatedPartyTransactions: null,
    customerConcentration: null,
    socialPresence: null,
    proprietorshipFirm: null,
    icpSector: null,
    arr: null,
    growthPercent: null,
    debtPercent: null,
    runway: null,
    ebitdaPercent: null,
    vintage : null,
    legalNonCompliance : null,
    uwSignalUpdatedBy: updatedByDealEvaluation.ADMIN,
    promoterCibilScoreUpdatedBy: updatedByDealEvaluation.ADMIN,
    companyCibilScoreUpdatedBy: updatedByDealEvaluation.ADMIN,
    receivablesUpdatedBy: updatedByDealEvaluation.ADMIN,
    relatedPartyTransactionsUpdatedBy: updatedByDealEvaluation.ADMIN,
    customerConcentrationUpdatedBy: updatedByDealEvaluation.ADMIN,
    socialPresenceUpdatedBy: updatedByDealEvaluation.ADMIN,
    proprietorshipFirmUpdatedBy: updatedByDealEvaluation.ADMIN,
    icpSectorUpdatedBy: updatedByDealEvaluation.ADMIN,
    arrUpdatedBy: updatedByDealEvaluation.ADMIN,
    growthPercentUpdatedBy: updatedByDealEvaluation.ADMIN,
    debtPercentUpdatedBy: updatedByDealEvaluation.ADMIN,
    runwayUpdatedBy: updatedByDealEvaluation.ADMIN,
    vintageUpdatedBy : updatedByDealEvaluation.ADMIN,
    legalNonComplianceUpdatedBy : updatedByDealEvaluation.ADMIN
  }

export const limitRoadmapMonths = [
'1',
'2',
'3',
'4',
'5',
'6',
'7',
'8',
'9',
'10',
]

export const CKYC_FILE_PATTERN = /^Ckyc Details_/;
export const CKYC_DETAILS ="Ckyc Details";
export const IP_CAPTURE_ACTIONS = {
  DEBT_SANCTION_COMPLETED: "DebtDealSanctionCompleted"
}

export const SORTING = {
  SORT_ASC: "ASCENDING",
  SORT_DES: "DESCENDING",
  SORT_NEU: "NEUTRAL"
}

export const QUERY_RAISED_CONSTANTS ={
  KYC_QUERY_RECEIVED : 'KYC Query Recieved',
  UW_QUERY_RECEIVED  : 'U/W Query Received'
}

export const DEAL_CURRENT_STAGE_FOR_QUERIES_MODAL = {
  DEAL_SENT : "Deal Sent",
  DEAL_VIEW : "Deal View",
  UW_QUERY_RECEIVED  : "U/W Query Received",
  ACCEPTED : "Accepted",
  MFA_SENT : "MFA Sent",
  MFA_SIGNED : "MFA Signed",
  KYC_DOCS_READY : "KYC & Docs Ready",
  KYC_DOCS_SHARED  : "KYC & Docs Shared", 
  KYC_QUERY_RECEIVED : "KYC Query Recieved", 
  SANCTION_APPROVED : "Sanction Approved"
}
export const WAIT_LISTING_TOAST_TEXT = "Terms for both Swift and Scale should be deactivated to waitlist a company";
export const MFA_SL_ALERT_MSG = "Last Signed Document was modified, do you still want to proceed with it?";
export const SANCTIONED_LIMIT = "Sanctioned Limit"
export const INVOICE_AMOUNT = "Invoice Amount"
export const MARK_SANCTION_COMPLETED  = "Status changed successfully";

export const DEBT_DOCS = {
  SANCTION_LETTER : "Sanction Letter",
  TRANSACTION_INVOICES : "Transaction Invoices",
  REPAYMENT_SCHEDULE:"Repayment Schedule"
}

export const DEBT_SANCTION_STATUS = {
  SANCTION_RECEIVED: "Sanction Received",
  SANCTION_ACCEPTED: "Sanction Accepted",
  SANCTION_DISBURSED: "Complete",
  INCOMPLETE: "Incomplete"
}

export const INVOICE_SORT_BY_TYPE = {
  INVOICE_DATE :"invoice_date",
  INVOICE_NUMBER : "invoice_number",
}

export const INVOICE_SORT_ORDER = {
  ASC : "asc",
  DESC : "desc"
}

export const JAVA_API_STATUS = {
  SUCCESS_200: 200,
  SUCCESS: 20,
  ERROR: 40,
};

export const CERBERUS_STATUS_CODES = {
  SUCCESS: 20,
  FAILURE: 40,
};